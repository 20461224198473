import './styles.scss';

import { F2AAuthentication } from '@components/modules/f2a-authentication';
import { Toggle } from '@components/modules/toggle';
import { LocationEnum } from '@enums/location.enum';
import { QrCode2FAEnum } from '@enums/qr-code-2fa.enum';
import { ILocation } from '@interfaces/location';
import { LocationComponent } from '@nuvioo/core-ui';
import { useForm } from '@refinedev/antd';
import {
    HttpError,
    useCustom,
    useCustomMutation,
    useGetIdentity,
    useTranslate,
    useUpdate,
} from '@refinedev/core';
import Response from '@responses/response';
import {
    Button,
    Col,
    Form,
    Image,
    Input,
    notification,
    Popconfirm,
    Row,
    Spin,
    UploadFile,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { AvatarUpload } from 'components/modules/avatar-upload';
import { getEnvConfig } from 'getEnvConfig';
import { useEffect, useState } from 'react';
import BaseApi from 'services/api/baseApi';

import { Hint } from '../../../components/modules/hint';
import { UserTypeEnum } from '../../../enums/user-type.enum';
import { IContact } from '../../../interfaces/contact';
import { IUser } from '../../../interfaces/user';
import { maskPhone } from '../../../utils/string';
interface IProfileSettingProps {
    name?: string;
}

enum ProfileSettingFormField {
    FULLNAME = 'fullname',
    EMAIL = 'email',
    PHONE_NUMBER = 'phone',
    CITY = 'city',
    STATE = 'state',
    COUNTRY = 'country',
    COMPANY = 'company',
    FIRSTNAME = 'firstName',
    LASTNAME = 'lastName',
    LOCATION = 'location',
    F2A = 'f2a',
}

enum PasswordFormField {
    PASSWORD = 'password',
    NEW_PASSWORD = 'newPassword',
    CONFIRM_NEW_PASSWORD = 'confirmNewPassword',
}

const baseApi = new BaseApi();

export const ProfileSetting: React.FC<IProfileSettingProps> = ({ name }: IProfileSettingProps) => {
    const [location, setLocation] = useState<ILocation.ILocationResponse | null>(null);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [user, setUser] = useState<IUser.IUserDto>();
    const [profileForm] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const translate = useTranslate();
    const apiUrl = getEnvConfig.PORTAL_URL;
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    const isClient = userIdentity?.userType === UserTypeEnum.CLIENT;
    const { mutate: updatePasswordMutate } = useCustomMutation();
    const { mutate: updateAvatar } = useCustomMutation();
    const [isUpdateImage, setIsUpdateImage] = useState<boolean>(true);

    const [isF2AEnabled, setIsF2AEnabled] = useState<boolean>(false);
    const [isOpenF2AModal, setIsOpenF2AModal] = useState<boolean>(false);

    const { mutate: mutateUpdateContact } = useUpdate<
        Response<IContact.IContact>,
        HttpError,
        IContact.IUpdateContactProfileRequest
    >();

    const { mutate: mutateUpdateF2A } = useCustomMutation();

    const { formLoading: loadingProfile, onFinish: onUpdateProfile } = useForm({
        action: 'edit',
        resource: 'v1/users/profile',
        redirect: false,
        successNotification: () => {
            return {
                type: 'success',
                message: translate('settings.update_profile_successfully'),
            };
        },
        errorNotification: () => {
            return {
                type: 'error',
                message: translate('settings.update_profile_failed'),
            };
        },
    });

    const { data, isLoading } = useCustom<IUser.IUserDto>({
        url: `${apiUrl}/v1/users/profile`,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });

    useEffect(() => {
        if (data?.data?.id) {
            const user = data?.data;
            setUser(user);
            setIsF2AEnabled(!!user?.tokenTwoFactorAuthentication);
            profileForm.setFieldsValue({
                [ProfileSettingFormField.FIRSTNAME]: user.firstName,
                [ProfileSettingFormField.LASTNAME]: user.lastName,
                [ProfileSettingFormField.PHONE_NUMBER]: maskPhone(user.phone || ''),
                [ProfileSettingFormField.CITY]: user.city,
                [ProfileSettingFormField.STATE]: user.state,
                [ProfileSettingFormField.COUNTRY]: user.country,
                [ProfileSettingFormField.COMPANY]: user.company,
                [ProfileSettingFormField.F2A]: !!user?.tokenTwoFactorAuthentication,
            });
            if (user?.location) {
                setLocation(user.location);
            }
        }
    }, [data]);

    const onValidation = async (result?: boolean, data?: ILocation.ILocationResponse) => {
        try {
            const formValue = await profileForm.validateFields();
            setIsSubmit(false);

            if (!result || !data) {
                return;
            }

            const { postalCode, ...rest } = data;

            if (formValue) {
                const request: IUser.IUpdateUser = {
                    firstName: formValue[ProfileSettingFormField.FIRSTNAME],
                    lastName: formValue[ProfileSettingFormField.LASTNAME],
                    email: user?.email ?? '',
                    phone: formValue[ProfileSettingFormField.PHONE_NUMBER],
                    city: formValue[ProfileSettingFormField.CITY],
                    state: formValue[ProfileSettingFormField.STATE],
                    country: formValue[ProfileSettingFormField.COUNTRY],
                    company: formValue[ProfileSettingFormField.COMPANY],
                    location: rest,
                };
                if (isClient) {
                    // delete request.phone;
                    const updateContactRequest: IContact.IUpdateContactProfileRequest = {
                        ...request,
                        phoneNumber: formValue[ProfileSettingFormField.PHONE_NUMBER],
                    };
                    updateContact(updateContactRequest);
                } else {
                    await onUpdateProfile(request);
                }
                setUser({
                    ...user,
                    firstName: request.firstName,
                    lastName: request.lastName,
                    email: request.email,
                    phone: request.phone,
                    city: request.city,
                    state: request.state,
                    country: request.country,
                    company: request.company,
                });
                if (request.location) {
                    setLocation(request.location);
                }
                setIsSubmit(false);
                setIsEdit(false);
            }
        } catch (err) {
            setIsEdit(false);
            setIsSubmit(false);
            console.error(err);
        }
    };

    const onSave = () => {
        setIsSubmit(true);
    };

    const updateContact = async (updateContactRequest: IContact.IUpdateContactProfileRequest) => {
        mutateUpdateContact(
            {
                resource: 'v1/contacts/profile',
                id: data?.data?.id as string,
                values: updateContactRequest,
                successNotification: { message: 'Successfully updated', type: 'success' },
                errorNotification: { message: 'Update failed', type: 'error' },
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                },
                onSuccess: (data, variables, context) => {
                    // Let's celebrate!
                },
            },
        );
    };

    const onEdit = () => {
        setIsEdit(true);
    };

    const uploadAvatar = async (file: UploadFile<any> | null) => {
        if (!file) {
            return;
        }
        const formData = baseApi.makeFormData({ file: file?.originFileObj });
        if (isClient) {
            updateAvatar({
                url: `${apiUrl}/v1/contacts/avatar`,
                method: 'put',
                values: formData,
                config: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                    },
                },
                successNotification() {
                    return {
                        type: 'success',
                        message: translate('settings.update_avatar_successfully'),
                    };
                },
                errorNotification() {
                    return { type: 'error', message: translate('update_err') };
                },
            });
        } else {
            updateAvatar({
                url: `${apiUrl}/v1/users/avatar`,
                method: 'put',
                values: formData,
                config: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                    },
                },
                successNotification() {
                    return {
                        type: 'success',
                        message: translate('settings.update_avatar_successfully'),
                    };
                },
                errorNotification() {
                    return { type: 'error', message: translate('update_err') };
                },
            });
        }
    };

    const onUpdatePassword = async () => {
        const passwordFormValue = await passwordForm.validateFields();
        if (passwordFormValue) {
            const updatePassword: IUser.IUpdatePassword = {
                oldPassword: passwordFormValue[PasswordFormField.PASSWORD],
                newPassword: passwordFormValue[PasswordFormField.NEW_PASSWORD],
            };
            updatePasswordMutate(
                {
                    url: `${apiUrl}/v1/auth/password`,
                    method: 'put',
                    values: updatePassword,
                    config: {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                        },
                    },
                    successNotification: false,
                    errorNotification: false,
                },
                {
                    onSuccess: (res, _, __) => {
                        if (res?.data) {
                            notification.success({
                                message: translate('settings.update_password_successfully'),
                            });
                        } else {
                            notification.error({
                                message: translate('settings.update_password_failed'),
                            });
                        }
                    },
                    onError: (error) => {
                        notification.error({
                            message: error?.message
                                ? `${error?.message}`
                                : translate('settings.update_password_failed'),
                            type: 'error',
                        });
                    },
                },
            );
        }
    };

    const handleF2AChange = (secret: string, token: string) => {
        if (!user?.id) return;

        mutateUpdateF2A(
            {
                url: `${apiUrl}/v1/users/f2a`,
                method: 'put',
                values: {
                    token,
                    secret,
                    userId: user.id,
                    isEnabled: isF2AEnabled,
                },
            },
            {
                onError: (error, _, __) => {
                    console.log(error);
                    notification.error({
                        message: translate('update_err'),
                    });
                },
                onSuccess: (data, _, __) => {
                    if (data?.data?.data) {
                        setIsOpenF2AModal(false);
                        setIsF2AEnabled(isF2AEnabled);
                        profileForm.setFieldsValue({
                            [ProfileSettingFormField.F2A]: isF2AEnabled,
                        });

                        notification.success({
                            message: translate('update_success'),
                        });
                    } else {
                        notification.error({
                            message: translate('update_err'),
                        });
                    }
                },
            },
        );
    };

    return (
        <Spin spinning={isLoading || loadingProfile} className="w-full h-full">
            <Row className="w-full h-full profile-settings-container py-8">
                <Col sm={6} className="w-full profile-setting-col avatar">
                    <div className="h-full profile-setting-avatar-row flex flex-col items-center">
                        <div className="user-avatar-wrapper relative cursor-pointer">
                            <AvatarUpload
                                width={105}
                                height={105}
                                uploadText={''}
                                onChange={uploadAvatar}
                                hideUploadBtn={true}
                                isCircle={true}
                                value={user?.avatarUrl}
                                statusEmitChange="done"
                                setIsUpdateImage={setIsUpdateImage}
                                isUpdateImage={isUpdateImage}
                            />
                            <div className="update-profile-icon absolute flex justify-center items-center">
                                {isEdit ? (
                                    <Image
                                        src={'/images/icons/accept.svg'}
                                        preview={false}
                                        onClick={onSave}
                                    />
                                ) : (
                                    <Image
                                        src={'/images/icons/pen.svg'}
                                        preview={false}
                                        onClick={onEdit}
                                    />
                                )}
                            </div>
                        </div>
                        {isEdit && (
                            <div className="mt-2">
                                <Hint width={105} height={105} size={5} fileType={['JPG', 'PNG']} />
                            </div>
                        )}
                        {/* <div className="user-role-wrapper w-full flex justify-center mt-4">
                            <span className="user-role">{'Super admin'}</span>
                        </div> */}
                    </div>
                </Col>
                <Col sm={18} className="w-full profile-setting-col information">
                    <Row className="profile-setting-information-row h-full">
                        {isEdit ? (
                            <Col
                                sm={24}
                                md={13}
                                className="profile-setting-information-col w-full md:px-10 sm:px-5"
                            >
                                <Form
                                    form={profileForm}
                                    layout="vertical"
                                    className="form-container"
                                >
                                    {/* BASIC INFORMATION FORM ITEMS */}
                                    <h3 className="setting-information-section mb-6">
                                        {translate('settings.basic_information')}
                                    </h3>
                                    <Form.Item
                                        name={ProfileSettingFormField.FIRSTNAME}
                                        label={`${translate('settings.first_name')}`}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('settings.first_name_required'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={translate('settings.first_name')} />
                                    </Form.Item>
                                    <Form.Item
                                        name={ProfileSettingFormField.LASTNAME}
                                        label={`${translate('settings.last_name')}`}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('settings.last_name_required'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={`${translate('settings.last_name')}`} />
                                    </Form.Item>
                                    {isClient && (
                                        <Form.Item
                                            name={ProfileSettingFormField.PHONE_NUMBER}
                                            label={translate('settings.phone_number')}
                                            rules={[
                                                {
                                                    pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                                    message: translate(
                                                        'settings.phone_number_invalid',
                                                    ),
                                                },
                                                {
                                                    required: true,
                                                    message: translate(
                                                        'settings.phone_number_required',
                                                    ),
                                                },
                                            ]}
                                        >
                                            <MaskedInput
                                                mask={
                                                    //  https://imask.js.org/guide.html#masked-pattern
                                                    '(000)-000-0000'
                                                }
                                            />
                                        </Form.Item>
                                    )}
                                    {!isClient && (
                                        <Form.Item
                                            name={ProfileSettingFormField.PHONE_NUMBER}
                                            label={translate('settings.phone_number')}
                                            rules={[
                                                {
                                                    pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                                    message: translate(
                                                        'settings.phone_number_invalid',
                                                    ),
                                                },
                                            ]}
                                        >
                                            <MaskedInput
                                                mask={
                                                    //  https://imask.js.org/guide.html#masked-pattern
                                                    '(000)-000-0000'
                                                }
                                            />
                                        </Form.Item>
                                    )}
                                    <div className="user-info-item">
                                        <span className="user-info-item-label">
                                            {translate('settings.email')}
                                        </span>
                                        <span className="user-info-item-value">
                                            {user?.email ? user?.email : ''}
                                        </span>
                                    </div>

                                    {/* ADDITIONAL INFORMATION FORM ITEMS*/}
                                    <h3 className="setting-information-section mb-6">
                                        {translate('settings.additional_information')}
                                    </h3>

                                    <LocationComponent
                                        location={location}
                                        isSubmit={isSubmit}
                                        setIsSubmit={setIsSubmit}
                                        onValidation={onValidation}
                                        isPosCode={false}
                                    />
                                    {/* JOB FORM ITEMS*/}
                                    <h3 className="setting-information-section mb-6">
                                        {translate('settings.job')}
                                    </h3>
                                    <Form.Item
                                        name={ProfileSettingFormField.COMPANY}
                                        label={translate('settings.company')}
                                        rules={[
                                            {
                                                required: isClient,
                                                message: translate('settings.company_required'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={translate('settings.company')} />
                                    </Form.Item>

                                    {/* F2A FORM ITEMS */}
                                    <h3 className="setting-information-section mb-6">
                                        {translate('settings.f2a')}
                                    </h3>
                                    <Toggle
                                        value={isF2AEnabled}
                                        onChange={(value) => {
                                            setIsF2AEnabled(value);
                                            setIsOpenF2AModal(true);
                                        }}
                                    />
                                </Form>
                            </Col>
                        ) : (
                            <Col
                                sm={24}
                                md={13}
                                className="profile-setting-information-col w-full md:px-10 sm:px-5"
                            >
                                {/* BASIC INFORMATION FORM ITEMS */}
                                <h3 className="setting-information-section mb-6">
                                    {translate('settings.basic_information')}
                                </h3>

                                <div className="user-info-item">
                                    <span className="user-info-item-label">
                                        {translate('settings.first_name')}
                                    </span>
                                    <span className="user-info-item-value">
                                        {user?.firstName ? user?.firstName : ''}
                                    </span>
                                </div>

                                <div className="user-info-item">
                                    <span className="user-info-item-label">
                                        {translate('settings.last_name')}
                                    </span>
                                    <span className="user-info-item-value">
                                        {user?.lastName ? user?.lastName : ''}
                                    </span>
                                </div>

                                <div className="user-info-item">
                                    <span className="user-info-item-label">
                                        {translate('settings.phone_number')}
                                    </span>
                                    <span className="user-info-item-value">
                                        {user?.phone ? maskPhone(user?.phone) : ''}
                                    </span>
                                </div>

                                <div className="user-info-item">
                                    <span className="user-info-item-label">
                                        {translate('settings.email')}
                                    </span>
                                    <span className="user-info-item-value">
                                        {user?.email ? user?.email : ''}
                                    </span>
                                </div>

                                {/* ADDITIONAL INFORMATION FORM ITEMS*/}
                                <h3 className="setting-information-section mb-6">
                                    {translate('settings.additional_information')}
                                </h3>

                                {location?.country.value == LocationEnum.US ? (
                                    <>
                                        <div className="user-info-item">
                                            <span className="user-info-item-label">
                                                {translate('settings.city')}
                                            </span>
                                            <span className="user-info-item-value">
                                                {location?.city?.name ? location?.city?.name : ''}
                                            </span>
                                        </div>

                                        <div className="user-info-item">
                                            <span className="user-info-item-label">
                                                {translate('settings.state')}
                                            </span>
                                            <span className="user-info-item-value">
                                                {location?.state?.name ? location?.state?.name : ''}
                                            </span>
                                        </div>

                                        <div className="user-info-item">
                                            <span className="user-info-item-label">
                                                {translate('settings.country')}
                                            </span>
                                            <span className="user-info-item-value">
                                                {location?.country?.name
                                                    ? location?.country?.name
                                                    : ''}
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="user-info-item">
                                            <span className="user-info-item-label">
                                                {translate('settings.city')}
                                            </span>
                                            <span className="user-info-item-value">
                                                {location?.city?.name ? location?.city?.name : ''}
                                            </span>
                                        </div>

                                        <div className="user-info-item">
                                            <span className="user-info-item-label">
                                                {translate('settings.district')}
                                            </span>
                                            <span className="user-info-item-value">
                                                {location?.district?.name
                                                    ? location?.district?.name
                                                    : ''}
                                            </span>
                                        </div>

                                        <div className="user-info-item">
                                            <span className="user-info-item-label">
                                                {translate('settings.ward')}
                                            </span>
                                            <span className="user-info-item-value">
                                                {location?.ward?.name ? location?.ward?.name : ''}
                                            </span>
                                        </div>

                                        <div className="user-info-item">
                                            <span className="user-info-item-label">
                                                {translate('settings.country')}
                                            </span>
                                            <span className="user-info-item-value">
                                                {location?.country?.name
                                                    ? location?.country?.name
                                                    : ''}
                                            </span>
                                        </div>
                                    </>
                                )}

                                {/* JOB FORM ITEMS*/}
                                <h3 className="setting-information-section mb-6">
                                    {translate('settings.job')}
                                </h3>

                                <div className="user-info-item">
                                    <span className="user-info-item-label">
                                        {translate('settings.company')}
                                    </span>
                                    <span className="user-info-item-value">
                                        {user?.company ? user?.company : ''}
                                    </span>
                                </div>

                                {/* F2A FORM ITEMS */}
                                <h3 className="setting-information-section mb-6">
                                    {translate('settings.f2a')}
                                </h3>
                                <Toggle
                                    disabled
                                    value={isF2AEnabled}
                                    onChange={(value) => {
                                        setIsF2AEnabled(value);
                                        setIsOpenF2AModal(true);
                                    }}
                                />
                            </Col>
                        )}

                        <Col
                            sm={24}
                            md={11}
                            className="profile-setting-password-col w-full md:px-10 sm:px-5"
                        >
                            <Form form={passwordForm} layout="vertical" className="form-container">
                                {/* BASIC INFORMATION FORM ITEMS */}
                                <h3 className="setting-information-section mb-6">
                                    {translate('settings.change_password')}
                                </h3>
                                <Form.Item
                                    name={PasswordFormField.PASSWORD}
                                    label={translate('settings.previous_password')}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: translate('settings.old_password_required'),
                                        },
                                    ]}
                                >
                                    <Input placeholder="********" type="password" />
                                </Form.Item>
                                <Form.Item
                                    name={PasswordFormField.NEW_PASSWORD}
                                    label={translate('settings.new_password')}
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: translate('settings.new_password_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="********"
                                        type="password"
                                        onChange={() => {
                                            const confirmPass = passwordForm.getFieldValue(
                                                PasswordFormField.CONFIRM_NEW_PASSWORD,
                                            );

                                            if (confirmPass) {
                                                passwordForm.validateFields([
                                                    PasswordFormField.CONFIRM_NEW_PASSWORD,
                                                ]);
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={PasswordFormField.CONFIRM_NEW_PASSWORD}
                                    className="confirm-new-password-form-item"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: translate(
                                                'settings.confirm_password_required',
                                            ),
                                        },
                                        {
                                            validator: (value, _, __) => {
                                                const nPass = passwordForm.getFieldValue(
                                                    PasswordFormField.NEW_PASSWORD,
                                                );
                                                const confirmPass = passwordForm.getFieldValue(
                                                    PasswordFormField.CONFIRM_NEW_PASSWORD,
                                                );
                                                if (!confirmPass) return Promise.resolve();

                                                if (confirmPass !== nPass) {
                                                    return Promise.reject();
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                            message: translate('settings.password_not_match'),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={translate('settings.confirm_new_password')}
                                        type="password"
                                    />
                                </Form.Item>

                                <Popconfirm
                                    title={translate('users.confirm_change_password')}
                                    placement="rightBottom"
                                    onConfirm={() => {
                                        onUpdatePassword();
                                    }}
                                    okText={translate('actions.yes')}
                                    cancelText={translate('actions.no')}
                                >
                                    <Button className="mt-10 w-full change-password-button flex justify-center">
                                        {translate('settings.change_password')}
                                    </Button>
                                </Popconfirm>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {isOpenF2AModal && user?.id ? (
                <F2AAuthentication
                    userId={user.id}
                    handleSubmit={handleF2AChange}
                    secretKey2fa={user?.tokenTwoFactorAuthentication ?? ''}
                    type={isF2AEnabled ? QrCode2FAEnum.CREATE : QrCode2FAEnum.REMOVE}
                    handleClose={() => {
                        setIsOpenF2AModal(false);
                        setIsF2AEnabled(!isF2AEnabled);
                        profileForm.setFieldsValue({
                            [ProfileSettingFormField.F2A]: !isF2AEnabled,
                        });
                    }}
                />
            ) : null}
        </Spin>
    );
};
