import { F2A_NUMBER_OF_DIGIT } from '@constants/index.constant';
import { QrCode2FAEnum } from '@enums/qr-code-2fa.enum';
import { useApiUrl, useCustomMutation } from '@refinedev/core';
import { Button, Form, Input, Modal, notification, QRCode } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';

type F2AAuthenticationProps = {
    userId: string;
    type: QrCode2FAEnum;
    secretKey2fa: string;
    handleClose: () => void;
    handleSubmit: (secret: string, token: string) => void;
};

export const F2AAuthentication: FC<F2AAuthenticationProps> = ({
    userId,
    type,
    secretKey2fa,
    handleClose,
    handleSubmit,
}) => {
    const apiUrl = useApiUrl();

    const [form] = Form.useForm();
    const { t } = useTranslation(['common']);

    const [otp, setOtp] = useState<string>('');
    const [secretKey, setSecretKey] = useState<string>('');
    const [otpAuthUrl, setOtpAuthUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { mutate: mutateGenerateQRCode } = useCustomMutation();

    useEffect(() => {
        if (type === QrCode2FAEnum.CREATE) {
            generateQrCode();
        } else {
            setSecretKey(secretKey2fa);
        }
    }, [userId]);

    const isValidOtp = useMemo(() => {
        const arr = otp?.split('');
        if (!arr?.length) return false;

        if (!otp || otp.length < F2A_NUMBER_OF_DIGIT || arr.some((x) => isNaN(parseInt(x)))) {
            return false;
        }

        return true;
    }, [otp]);

    const renderContent = useCallback(() => {
        switch (type) {
            case QrCode2FAEnum.CREATE:
                return (
                    <>
                        <h3 className="title-24-bold mb-3 text-center">
                            {t('f2a_authentication.change')}
                        </h3>
                        <p className="mb-2 font-bold">{t('f2a_authentication.title_step')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_create_one')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_create_two')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_create_three')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_create_four')}</p>

                        {otpAuthUrl && (
                            <div className="flex items-center justify-center flex-col mt-6">
                                <QRCode value={otpAuthUrl} />
                                <div className="text-center">
                                    <span>{t('f2a_authentication.can_not_scan')}</span>
                                    <span className="ml-1" onClick={handleCopyToClipboard}>
                                        {t('f2a_authentication.get_code')}
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                );

            case QrCode2FAEnum.REMOVE:
                return (
                    <>
                        <h3 className="title-24-bold mb-3 text-center">
                            {t('f2a_authentication.remove')}
                        </h3>
                        <p className="mb-2 font-bold">{t('f2a_authentication.title_step')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_remove_one')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_remove_two')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_remove_three')}</p>
                    </>
                );

            case QrCode2FAEnum.VERIFY:
                return (
                    <>
                        <h3 className="title-24-bold mb-4 text-center">
                            {t('f2a_authentication.verify')}
                        </h3>
                        <p className="mb-2 font-bold">{t('f2a_authentication.title_step')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_remove_one')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_remove_two')}</p>
                        <p className="mb-1">{t('f2a_authentication.step_verify_three')}</p>
                    </>
                );
        }
    }, [type, otpAuthUrl]);

    const generateQrCode = async () => {
        mutateGenerateQRCode(
            {
                values: {},
                method: 'post',
                url: `${apiUrl}/v1/users/f2a/${userId}`,
            },
            {
                onError: (error, _, __) => {
                    console.error(error);
                    notification.error({
                        message: t('f2a_authentication.error'),
                    });
                },
                onSuccess: (data, _, __) => {
                    if (data?.data?.isSuccess) {
                        setSecretKey(data?.data?.data?.secret);
                        setOtpAuthUrl(data?.data?.data?.otpAuthUrl);

                        return;
                    }

                    notification.error({
                        message: t('f2a_authentication.error'),
                    });
                },
            },
        );
    };

    const handleValidateForm = (): void => {
        setIsLoading(true);

        form.validateFields()
            .then(() => {
                handleSubmit(secretKey, otp);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleOtpChange = (otpCode: string) => {
        setOtp(otpCode);
        form.setFieldsValue({ otp: otpCode });
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(secretKey);
        notification.success({
            message: t('f2a_authentication.copy_success'),
        });
    };

    return (
        <Modal
            open
            width={650}
            footer={null}
            maskClosable={false}
            onCancel={handleClose}
            title={t('f2a_authentication.title')}
        >
            <section className="p-4 border border-neutral-20 rounded-lg">
                {renderContent()}

                <Form form={form} autoComplete="off" onFinish={handleValidateForm}>
                    <div className="flex items-center justify-center">
                        <Form.Item
                            name="otp"
                            rules={[
                                {
                                    required: true,
                                    message: t('f2a_authentication.token_required'),
                                },
                                {
                                    validator: (_, value) => {
                                        if (isValidOtp || !value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            t('f2a_authentication.token_invalid'),
                                        );
                                    },
                                },
                            ]}
                        >
                            <Input className="hidden" name="otp" />

                            <div className="otp mt-3">
                                <OtpInput
                                    value={otp}
                                    onChange={handleOtpChange}
                                    numInputs={F2A_NUMBER_OF_DIGIT}
                                    renderInput={(props) => (
                                        <input {...props} inputMode="numeric" />
                                    )}
                                />
                            </div>
                        </Form.Item>
                    </div>

                    <div className="flex items-center justify-center">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            className="title-16-medium mt-2"
                        >
                            {t(`f2a_authentication.action.${type}`)}
                        </Button>
                    </div>
                </Form>

                {type === QrCode2FAEnum.CREATE && (
                    <div className="text-center mt-4">
                        <span>{t('f2a_authentication.forget')}</span>
                        <span
                            onClick={handleCopyToClipboard}
                            className="ml-1 cursor-pointer font-bold"
                        >
                            {t('f2a_authentication.get_code')}
                        </span>
                    </div>
                )}
            </section>
        </Modal>
    );
};
